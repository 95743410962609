<template>
    <div class="member-type-chart">
        <div class="summary">
            <p class="chart-title" v-if="total">会员类型占比</p>
            <p class="total">共{{total|valueKbit}}张</p>
        </div>
        <div id="memberTypeChart"></div>
    </div>
</template>

<script>
import table from '@/api/table'
import {mapState} from 'vuex'

	export default {
		name:"revenue",
		data(){
			return {
				chartData:null,
				renderData:[],
				total:0,
			}
		},
		watch:{
			chartData:{
				handler(val){
					let total = 0
					for(let item of val){
						for(let key in item){
							total += parseInt(item[key])
						}
					}
					this.total = total
				},
				deep:true
			},
			'userStatus.curs_node_id_2':{
				handler(){
					this.loadData()
				},
				deep:true,
			},
		},
		computed:{
			...mapState(['userStatus']),
		},
    mounted () {
    this.loadData()
    },
		methods:{
			loadData(){
				this.getMemberTypeRate().then(() =>{
					this.resolveChartData()
					this.renderChart()
				})
			},
			/**
			 * 获取营收首页7日走势图
			 */
			getMemberTypeRate(){
				// let {shopNo,openid} = this
				// let params = {
				// 	shopNo,
				// 	openid,
				// }
				return new Promise((resolve,reject) =>{
					table.getMemberTypeRate().then(res =>{
						this.chartData = res.data.data
						resolve()
					}).catch(err =>{
						console.log("err",err.info)
						reject()
					})
				})
			},

			/**
			 * 处理图表数据
			 */
			resolveChartData(){
				let renderData = []
				let {chartData} = this
				for(let item of chartData){
					for(let key in item){
						let renderItem = {
							value:item[key],
							name:key
						}
						renderData.push(renderItem)
					}
				}
				this.renderData = renderData
			},

			/**
			 * 渲染7日走势图
			 */
			renderChart(){
				let chart = this.$echarts.init(document.querySelector('#memberTypeChart'))
				var {renderData} = this
				let option = {
					grid:{
						left:'0',
						right:'0',
						bottom:'0',
						top:'0',
						containLabel:true
					},
					tooltip:{
						trigger:'axis'
					},
					color:[
						'#007bFF','#00CCFF','#FCCE10','#E95F2B','#ffc62b',
						'#817efa','#9BCA63','#FAD860','#F3A43B','#60C0DD',
						'#D7504B','#C6E579','#F4E001','#F0805A','#26C0C0'
					],
					series:[
						{
							type:'pie',
							radius:'65%',
							center:['50%','50%'],
							selectedMode:'single',
							avoidLabelOverlap: true,
							data:renderData,

							// label:{
							// 	normal:{
							// 		show:true,
							// 		position:'out',
							// 		align: 'left',
							// 		formatter(v) {
							// 			let text = v.name
							// 			if(text.length <= 8)
							// 			{
							// 				return text;
							// 			}else if(text.length > 8 && text.length <= 16){
							// 				return text = `${text.slice(0,8)}\n${text.slice(8)}`
							// 			}
							// 		},
							// 	},
							// },

							tooltip:{
								trigger:'item',
								formatter:"占比：{d}%<br/>数量：{c}张"
							},
							labelLine:{
								normal:{
									show:true
								}
							},
							itemStyle:{
								emphasis:{
									shadowBlur:10,
									shadowOffsetX:0,
									shadowColor:'rgba(0, 0, 0, 0.5)'
								},
							}
						}
					]
				};
				chart.setOption(option)
			},

		},
        mounted(){
			this.loadData()
        }
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .member-type-chart{
        background:#FFFFFF;
        margin:18px 0 0 0;
    }

    .chart-title{
        font-size:32px;
        text-align:center;
        color:rgb(51, 51, 51);
        font-weight:bold;
        @extend %flex-center-row;
    }

    .chart-title:after,
    .chart-title:before{
        content:'';
        width:100px;
        height:3px;
        background:rgb(238, 238, 238);
        margin:0 20px;
    }

    #memberTypeChart{
        height:580px;
    }

    .total{
        font-size:32px;
        color:rgb(51, 51, 51);
        font-weight:bold;
        text-align:center;
    }

    .summary{
        padding:40px 0 0;
        line-height:50px;
    }
</style>
